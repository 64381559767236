<template>
  <v-row>
    <v-col>

      <div class="mb-5">
        <new-button class="mr-5" @click="openAddNew">Adauga Beneficiu nou</new-button>
      </div>
      <vx-card>

        <v-data-table :headers="headers" :hide-default-footer="true" :items="rewards" show-expand>

          <template v-slot:item.image="{item}">
            <img :alt="item.name" :src="item.full_image_path" width="75"/>
          </template>

          <template v-slot:item.actions="{item}">
            <edit-button @click="editItem(item)"></edit-button>
            <delete-button @delete="deleteItem(item)"></delete-button>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div><strong>Descriere:</strong></div>
              <div>{{ item.description }}</div>
            </td>
          </template>

        </v-data-table>
      </vx-card>
    </v-col>
  </v-row>
</template>

<script>
import {EventBus} from '@/plugins/event-bus'
import draggable from 'vuedraggable'
import DeleteButton from '@/components/general-form/DeleteButton'
import EditButton from '@/components/general-form/EditButton'
import NewButton from '@/components/general-form/NewButton'

export default {
  name: 'ClientRewardsList',
  components: {
    NewButton,
    EditButton,
    DeleteButton,
    draggable
  },
  data () {
    return {
      rewards: [],
      headers: [
        {
          text: 'Imagine',
          value: 'image',
          width: '80',
          align: 'center',
          sortable: false
        },
        {
          text: 'Puncte necesare',
          value: 'milestone',
          width: '150',
          align: 'center',
          sortable: false
        },
        {
          text: 'Nume',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '120',
          sortable: false,
          align: 'right'
        },
        {
          text: '',
          value: 'data-table-expand'
        }
      ]
    }
  },
  methods: {
    editItem (item) {
      this.$store.dispatch('rewards/editItem', item)
    },
    deleteItem (item) {
      this.$http.delete(`rewards/${item.id}`)
        .then(() => {
          this.loadRewards()
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
      console.log('delete Item')
      console.log(item)
    },
    loadRewards () {
      this.$http.get('rewards')
        .then(({data}) => {
          this.rewards = data
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
    },
    openAddNew () {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-new-client-reward',
        title: 'Adauga o categorie noua'
      })
    }
  },
  created () {
    this.loadRewards()
    EventBus.$on('created', this.loadRewards)
  },
  destroyed () {
    EventBus.$off('created', this.loadRewards)
  }
}
</script>
