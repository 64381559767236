var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('div',{staticClass:"mb-5"},[_c('new-button',{staticClass:"mr-5",on:{"click":_vm.openAddNew}},[_vm._v("Adauga Beneficiu nou")])],1),_c('vx-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":true,"items":_vm.rewards,"show-expand":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"alt":item.name,"src":item.full_image_path,"width":"75"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('edit-button',{on:{"click":function($event){return _vm.editItem(item)}}}),_c('delete-button',{on:{"delete":function($event){return _vm.deleteItem(item)}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('strong',[_vm._v("Descriere:")])]),_c('div',[_vm._v(_vm._s(item.description))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }